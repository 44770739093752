<template>
    <div class="sports_bet_list">
        <table class="table_sports_bet" style="margin-top: 10px;border: 2px solid #ffa500"
               v-for="item in betList">
            <tr style="border-top: 4px solid #0d88c1"
                :class="{'bg-danger':item.leisureGame.autoclosed}">
                <th style="width: 10%;">일회차</th>
                <th style="width: 25%;" colspan="2">경기유형</th>
                <th style="width: 10%;">경기일자</th>
                <th style="width: 10%;">베팅시간</th>
                <th style="width: 10%;">번호</th>
                <th style="width: 8%;">게임번호</th>
            </tr>
            <tr>
                <td class="bg-blue">{{item.leisureGame.sequence}}</td>
                <td colspan="2">{{item.kindConfig.kindName}}</td>
                <td>{{item.leisureGame.startDayStr}}</td>
                <td>{{item.createTime|datef('MM-DD HH:mm:ss')}}</td>
                <td>{{item.id}}</td>
                <td>{{item.gameId}}</td>
            </tr>
            <tr>
                <th>회원</th>
                <th>선택값Text</th>
                <th>베팅결과</th>
                <th>정산여부</th>
                <th>배당</th>
                <th>베팅금액</th>
                <th>예상당첨</th>
            </tr>
            <tr>
                <td style="cursor: pointer">
                    <span class="badge badge-success">{{item.user.nickname}}</span>
                </td>
                <td class="bg-gradient-success">
                    <span class="text-blue" style="font-weight: bold">({{item.selectedValueText}})</span>
                    <span> {{item.attributeConfig.attrName}}</span>
                    <span v-if="item.leisureGame.homeTeamName != null">[{{item.leisureGame.homeTeamName}} vs {{item.leisureGame.awayTeamName}}]</span>
                    <span v-if="item.leisureGame.homeTeamScore >=0 && item.leisureGame.awayTeamScore>=0">[{{item.leisureGame.homeTeamScore}}:{{item.leisureGame.awayTeamScore}}]</span>

                </td>
                <td>
                    <span v-if="item.betResult == agentConst.SportsBet.BET_RESULT_WAITING">대기중</span>
                    <span v-if="item.betResult == agentConst.SportsBet.BET_RESULT_WIN"
                          class="badge badge-primary">당첨</span>
                    <span v-if="item.betResult == agentConst.SportsBet.BET_RESULT_LOSE"
                          class="badge badge-danger">낙첨</span>
                    <span v-if="item.betResult == agentConst.SportsBet.BET_RESULT_CANCEL" class="badge badge-danger">취소</span>
                    <span v-if="item.betResult == agentConst.SportsBet.BET_RESULT_SPECIALCASE"
                          class="badge badge-danger">적특</span>
                    <span>
                         <br>{{item.operator}}
                    </span>

                </td>
                <td>
                    <span v-if="item.status == agentConst.SportsBet.STATUS_COMPLITE">정산완료</span>
                    <span v-if="item.status == agentConst.SportsBet.STATUS_WAITING">정산대기중</span>
                </td>
                <td>{{item.betOdds}}</td>
                <td>{{item.betCash|comma}}원</td>
                <td>{{item.totalCash|comma}}원</td>
            </tr>
        </table>
    </div>
</template>

<script>
    import Vue from "vue";
    import agentConst from "../../common/agent/agentConst";

    export default {
        name: "LeisureGameBetComp2",
        data() {
            return {
                agentConst: agentConst,
                betList: [],
            }
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                }
            },

        },
        methods: {

        },
        watch: {
            list: {
                handler(newVal) {
                    this.betList = newVal
                },
                immediate: true,
                deep: true
            },
        }
    }
</script>

<style scoped>
    .sports_bet_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .table_sports_bet {
        width: 100%;
        margin-bottom: 10px;
    }

    .table_bet_games {
        width: 100%;
    }

    .table_bet_games td {
        line-height: 40px !important;
        border-bottom: 1px solid #cfcfd1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 2px;
    }
</style>